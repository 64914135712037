<template>
  <div>
    <!--begin::Notice-->
    <b-jumbotron
      v-if="!company.has_portrait"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Die Option um ein Firmenprofil zu erfassen ist in Ihrem Abonment nicht
        enthalten.
      </template>
      <hr class="my-4" />
      <p>
        Sie können jedoch weitere Optionen jederzeit zu Ihrem Abonoment
        hinzufügen.
      </p>
      <router-link
        to="/add-subscription-option"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Optionen hinzufügen</b-button
        >
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->
    <!--begin::Card-->
    <div class="card card-custom" v-else>
      <!-- begin::Form-->
      <b-form class="form" v-on:submit.prevent="save">
        <!--begin::Body-->
        <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <h4 class="mb-10 font-weight-bold text-dark">
                Referenzprojekt
              </h4>

              <div class="row">
                <div class="col-xl-12">
                  <ValidatedInput
                    name="Projektbezeichnung"
                    v-model="companyReferenceProject.description"
                    :v="$v.companyReferenceProject.description"
                    errorMessage="Das ist ein Pflichtfeld."
                    :hasLengthLimit="true"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <ValidatedTextArea
                    name="Leistungen"
                    v-model="companyReferenceProject.services"
                    :v="$v.companyReferenceProject.services"
                    rows="4"
                    errorMessage="Das ist ein Pflichtfeld."
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Projektstatus</label>
                    <b-form-select
                      class="form-control form-control-lg form-control-solid"
                      size="lg"
                      v-model="companyReferenceProject.status"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled
                          >-- Option auswählen --</b-form-select-option
                        >
                      </template>
                      <option
                        v-for="(project_status,
                        key) in companyReferenceProject.project_statuses"
                        :key="key"
                        :value="project_status[0]"
                      >
                        {{ project_status[1] }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Auftraggeber</label>
                    <b-form-select
                      class="form-control form-control-lg form-control-solid"
                      size="lg"
                      v-model="companyReferenceProject.customer"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled
                          >-- Option auswählen --</b-form-select-option
                        >
                      </template>
                      <option
                        v-for="(project_customer,
                        key) in companyReferenceProject.project_customers"
                        :key="key"
                        :value="project_customer[0]"
                      >
                        {{ project_customer[1] }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <ValidatedNumberInput
                    name="Auftragssumme [CHF]"
                    v-model="companyReferenceProject.order_amount"
                    :v="$v.companyReferenceProject.order_amount"
                    min="0"
                    errorMessage="Das ist ein Pflichtfeld."
                    :show-formatted-number="true"
                    formatted-number-prefix="CHF"
                  />
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <label>Jahr</label>
                    <b-form-select
                      class="form-control form-control-lg form-control-solid"
                      size="lg"
                      v-model="companyReferenceProject.execution_year"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled
                          >-- Jahr auswählen --</b-form-select-option
                        >
                      </template>
                      <option v-for="year in years" :key="year" :value="year">
                        {{ year }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>

              <!--begin: Actions -->
              <div class="d-flex justify-content-end border-top pt-10">
                <div class="mr-2" />
                <div>
                  <router-link
                    to="/company-portrait"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <button
                      @click="navigate"
                      class="btn btn-danger font-weight-bold text-uppercase px-9 py-4 mr-4"
                    >
                      Abbrechen
                    </button>
                  </router-link>
                  <button
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Speichern
                  </button>
                </div>
              </div>
              <!--end: Actions -->
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";
import { alertMixin } from "@/mixins/alertMixin";
import ValidatedInput from "@/components/ValidatedInput";
import ValidatedTextArea from "@/components/ValidatedTextArea";
import ValidatedNumberInput from "@/components/ValidatedNumberInput";
import {
  LOAD_USER_COMPANY_REFERENCE_PROJECT,
  UPDATE_USER_COMPANY_REFERENCE_PROJECT
} from "@/store/referenceprojects.module";
import { LOAD_USER_COMPANY } from "@/store/company.module";

export default {
  components: { ValidatedInput, ValidatedNumberInput, ValidatedTextArea },
  mixins: [validationMixin, alertMixin],
  name: "EditReferenceProject",
  data() {
    return {
      companyReferenceProject: {},
      company: {},
      referenceProjectId: this.$route.params.id
    };
  },
  validations: {
    companyReferenceProject: {
      description: {
        required
      },
      services: {
        required
      },
      status: {
        required
      },
      customer: {
        required
      },
      order_amount: {
        required,
        numeric
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.company[name];
      return $dirty ? !$error : null;
    },
    loadData() {
      Promise.all([
        this.$store.dispatch(LOAD_USER_COMPANY),
        this.$store.dispatch(
          LOAD_USER_COMPANY_REFERENCE_PROJECT,
          this.referenceProjectId
        )
      ]).then(() => {
        this.companyReferenceProject = this.getUserCompanyReferenceProject;
        this.company = this.getUserCompany;
      });
    },
    save() {
      this.$v.companyReferenceProject.$touch();
      if (this.$v.companyReferenceProject.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      this.$store
        .dispatch(
          UPDATE_USER_COMPANY_REFERENCE_PROJECT,
          this.companyReferenceProject
        )
        .then(() => {
          this.companyReferenceProject = this.getUserCompanyReferenceProject;
          Swal.fire({
            title: "",
            text: "Ihre Änderungen wurden gespeichert!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-secondary"
            },
            willClose: () => {
              this.$router.push({ name: "company-portrait" });
            }
          });
        });
    }
  },
  computed: {
    ...mapGetters(["getUserCompanyReferenceProject", "getUserCompany"]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1930 },
        (value, index) => year - index
      );
    }
  }
};
</script>
